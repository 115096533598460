import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch} from 'react-redux';

import { RootState, AppDispatch } from '../../app/store';
import { ReactComponent as CloseIcon } from '../../assets/close-circle-icon.svg';
import TemplateDocuments from "../TemplateDocuments";
import { createTemplate, fetchTemplates } from "../../features/template/templateSlice";
import { selectCurrentNamespaceId } from "../../features/namespace/namespaceSlice";
import { createDocuments } from '../../features/document/documentSlice';
import { createTemplateDocument } from '../../features/template/templateSlice';

const ManageTemplateModal = () => {
    const dispatch:AppDispatch = useDispatch();
    const navigate = useNavigate();
    const templates = useSelector((state: RootState) => state.template.templates);
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState('new');
    const [templateName, setTemplateName] = useState('');
    // Memoize the query parameters to prevent them from triggering re-renders
    const query = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);

    useEffect(() => {
        const modal = query.get("modal");
        const templateId = query.get("modal_template_id");

        if (modal === "manage-templates") {
            setIsOpen(true);

            if (currentNamespaceId && templates.length === 0) { // Check to prevent unnecessary dispatch
                dispatch(fetchTemplates({ namespaceId: currentNamespaceId }));
            }

            const currentTemplate = templates.find(template => template.id === templateId);
            if (currentTemplate && selectedTemplateId !== currentTemplate.id) { // Prevent unnecessary state updates
                setSelectedTemplateId(currentTemplate.id);
                setTemplateName(currentTemplate.title);
            }
        }
    }, [currentNamespaceId, templates, query, selectedTemplateId, dispatch]);

    if(!currentNamespaceId) {
        return null
    }

    const handleClose = () => {
        // Reset Modal state
        setIsOpen(false);
        setSelectedTemplateId('new');
        setTemplateName('');
        navigate('.');
    };
    const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        setSelectedTemplateId(selectedValue);
        if (selectedValue === 'new') {
            setTemplateName('');
        } else {
            const selectedTemplateData = templates.find(template => template.id === selectedValue);
            setTemplateName(selectedTemplateData ? selectedTemplateData.title : '');
        }
        query.set("modal_template_id", selectedValue);
        navigate(`?${query.toString()}`);
    };

    const onSave = async () => {
        if (selectedTemplateId === 'new' && templateName && currentNamespaceId) {
            const { template } = await dispatch(createTemplate({ namespaceId: currentNamespaceId, title: templateName })).unwrap();
            dispatch(fetchTemplates({ namespaceId: currentNamespaceId }));
            query.set("modal_template_id", template.id);
            navigate(`?${query.toString()}`);
            setSelectedTemplateId(template.id);
        }
    }

    const onUploaded = async (fileId: string) => {
        const { documents } = await dispatch(createDocuments({namespaceId: currentNamespaceId, fileIds: [fileId]})).unwrap();
        const promises = documents.map(async (doc: any) => {
            await dispatch(createTemplateDocument({templateId: selectedTemplateId, documentId: doc.id}));
        });
        await Promise.all(promises);
    }
    
    let createContent;
    let templateDocumentContent;
    if(selectedTemplateId === 'new') {
        createContent = <button onClick={onSave} className="bg-[#2E1534] text-white px-28 py-3 rounded-xl shadow-sm hover:bg-[#1e0d21] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2E1534]">Create</button>
    } else {
        templateDocumentContent = <div className="flex-1 mb-4 h-full">
            <TemplateDocuments />
        </div>
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="w-full h-full absolute"
        >
            <div className="flex items-center justify-center min-h-screen w-full bg-gray-900 bg-opacity-50 h-full">
                <div className="relative bg-[#FCFAF5] rounded-lg shadow-lg p-8 w-[900px] h-4/5">
                    <button className="absolute top-4 right-4 focus:outline-none" onClick={handleClose}>
                        <CloseIcon width={30} height={30} />
                    </button>
                    <h2 id="modal-modal-title" className="text-xl font-semibold mb-6">Manage Styles</h2>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex-1">
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Template:</label>
                                <select
                                    value={selectedTemplateId}
                                    onChange={handleTemplateChange}
                                    className="block w-full border border-gray-300 rounded-xl py-2 px-3 shadow-sm bg-brown-grey-500 text-gray-500 focus:outline-none focus:ring-bg-brown-grey-700 focus:border-bg-brown-grey-700"
                                >
                                    <option value="new">New</option>
                                    {templates.map(template => (
                                        <option key={template.id} value={template.id}>
                                            {template.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Title:</label>
                                <input
                                    type="text"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    className="block w-full border border-gray-300 bg-brown-grey-500 rounded-xl py-2 px-3 shadow-sm focus:outline-none focus:ring-brown-grey-700 focus:border-brown-grey-700"
                                />
                            </div>
                            {templateDocumentContent}
                        </div>
                    </div>
                    <div className="flex justify-center mt-20">
                        {createContent}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ManageTemplateModal;
