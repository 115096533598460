import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';

import { AppDispatch } from "../../app/store";
import { ReactComponent as RightArrow } from '../../assets/arrow-right.svg'
import WorkspaceDocuments from '../WorkspaceDocuments';
import MemoGenDraft from '../MemoGenDraft';
import { fetchWorkspaces, selectWorkspaceById, createWorkspace } from "../../features/workspace/workspaceSlice";
import { selectCurrentNamespace } from "../../features/namespace/namespaceSlice";

const MemoGenWorkflow = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const currentNamespace = useSelector(selectCurrentNamespace);
    const workspace = useSelector(selectWorkspaceById(id));
    const [title, setTitle] = useState('');

    useEffect(() => {
        if(currentNamespace) {
            dispatch(fetchWorkspaces({namespaceId: currentNamespace?.id}));
        }
    }, [dispatch, currentNamespace]);

    if(!currentNamespace) {
        return null;
    }

    const handleInputChange = (e: any) => {
        setTitle(e.target.value);
    };

    const handleCreateClick = async () => {
        const {workspace: newWorkspace} = await dispatch(createWorkspace({title, namespaceId: currentNamespace.id})).unwrap();
        console.log(newWorkspace);
        // Swith the url path from "new" to the new workspace id without changing the previous path
        navigate(`/home/workspace/memo/${newWorkspace.id}`);
    };

    if (!workspace && id.toLowerCase() !== "new") {
        return null;
    }

    // If no workspace is selected, set workspaceTitleContent to be a div with a input field and a button to create the workspace
    const workspaceTitleContent = workspace ? <h3>{workspace.title}</h3> : 
    <div className="flex items-center">
        <input
            type="text"
            placeholder="Enter workspace title"
            autoFocus
            value={title}
            onKeyUp={(e)=> {if(e.key === "Enter") handleCreateClick()}}
            onChange={handleInputChange}
            className="bg-transparent border-gray-500 outline-none focus:outline-none"
        />
        {title && (
            <button
                onClick={handleCreateClick}
                className="bg-dark-purple-800 text-white text-sm px-4 py-2 rounded-xl ml-2"
            >
                Create
            </button>
        )}
    </div>

    return (
        <div className="flex flex-col h-full pt-20 w-full"> 
            <div className="flex flex-row items-center">
                <NavLink to="/home" className="mr-4 h-10 w-10 bg-brown-grey-500 px-2 rounded-full rotate-180 flex justify-center"> <RightArrow/> </NavLink>
                <div className="text-lg font-medium"> 
                    {workspaceTitleContent}
                </div>  
            </div>
            {/* If workspace is not selected, don't show MemoGenDraft and ask anything */}
            {workspace &&
                <div className="flex flex-row w-full pl-14 h-full mt-4">
                    <div className="flex flex-col justify-between w-4/6 h-full">
                        <div className="">
                            <MemoGenDraft/>
                        </div>
                        <div className="h-1/5 self-center">
                            {/* Ask anything */}
                        </div>
                    </div>
                    <div className="flex flex-col h-full flex-1 justify-between">
                        <WorkspaceDocuments/>
                    </div>
                </div>
            }
        </div>
    )
}

export default MemoGenWorkflow;