import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import Query from '../pages/Query';
import HomePreview from '../components/HomePreview';
import LoginForm from '../components/LoginForm';
import Layout from '../components/Layout';
import MemoGenWorkflow from '../components/MemoGenWorkflow';

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/" element={<Layout />}>
                {/* Default redirect to /home when accessing the root */}
                <Route path="" element={<Navigate to="/home" replace />} />
                
                <Route path="home" element={<Home />}>
                    <Route path="" element={<HomePreview />} />
                    <Route path="workspace/:type/:id" element={<MemoGenWorkflow />} />
                </Route>
                <Route path="query" element={<Query />} />
                <Route path="activity" element={<Home />} />
            </Route>
            {/* Catch-all route to redirect to /home */}
            <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
    );
}

export default AppRouter;
