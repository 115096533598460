import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../app/store";
import { fetchWorkspaces, selectWorkspaceIds } from "../../features/workspace/workspaceSlice";
import { selectCurrentNamespace } from "../../features/namespace/namespaceSlice";

import WorkspaceHistoryCapsule from "../WorkspaceHistoryCapsule";

const WorkspaceHistory = () => {
    const dispatch: AppDispatch = useDispatch();
    const currentNamespace = useSelector(selectCurrentNamespace);
    const workspaces = useSelector(selectWorkspaceIds);

    useEffect(() => {
        if(currentNamespace) {
            dispatch(fetchWorkspaces({namespaceId: currentNamespace?.id}));
        }
    }, [dispatch, currentNamespace]);

    const workspacesContent = workspaces.map((id: string) => {
        return <WorkspaceHistoryCapsule key={id} workspaceId={id}/>
    });

    return (
        <div className="flex flex-col mt-20">
            <h3 className="font-semibold text-lg pb-4 text-dark-purple-800"> Your Workspaces </h3>
            <div className="h-72 overflow-y-scroll"> 
                {workspacesContent}
            </div>
            
        </div>
    )
}

export default WorkspaceHistory;