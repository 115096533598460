import Chat from "../components/Chat";

const Query = () => {
    return (
        <div className="w-full flex flex-col">
            <h3 className="pb-4 text-xl font-medium"></h3>
            <Chat />
        </div>
    );
};

export default Query;