import { WebSocketClient } from '../lib/webSocketClient';
import pulseAPI from './pulse-api';

const PULSE_WEBSOCKET_URL = process.env.NODE_ENV === 'development' ? 'ws://localhost:3030/ws' : 'wss://ws.usepulse.co/ws';

export class Realtime {
    private wsClient?: WebSocketClient;

    constructor() {
        this.connect();
    }

    public connect() {
        const token = pulseAPI.getToken();
        if (!token) {
            console.warn('Cannot connect to Pulse websockets without a token');
            return;
        }
        this.wsClient = new WebSocketClient({
            url: PULSE_WEBSOCKET_URL,
            token,
        });
    }

    public on(event: string, callback: (data: any) => void) {
        if (!this.wsClient) {
            console.warn('Cannot add event listener without a websocket connection');
            return;
        }
        this.wsClient.on(event, callback);
    }

    public send(event: string, data: any) {
        if (!this.wsClient) {
            console.warn('Cannot send message without a websocket connection');
            return;
        }
        this.wsClient.send(event, data);
    }
}

export default new Realtime();
