import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { fetchWorkspaceDocuments, fetchTemplateDocuments, fetchDocuments, Document } from '../document/documentSlice';
import { RootState } from '../../app/store';

enum DOCUMENT_PROCESSING_STEP {
    NOT_STARTED = 'NOT_STARTED',
    PAGES_TO_IMAGES_COMPLETED = 'PAGES_TO_IMAGES_COMPLETED',
    EXTRACT_TEXT_COMPLETED = 'EXTRACT_TEXT_COMPLETED',
    VECTOR_DB_INSERT_COMPLETED = 'VECTOR_DB_INSERT_COMPLETED',
    COMPLETED = 'COMPLETED'
}

enum DOCUMENT_PROCESSING_STATUS {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
    DELETE_STARTED = 'DELETE_STARTED',
    DELETE_COMPLETED = 'DELETE_COMPLETED'
}

export type DocumentStatus = {
    documentId: string;
    status: DOCUMENT_PROCESSING_STATUS;
    step: DOCUMENT_PROCESSING_STEP;
};

type documentProcessingStatusState = {
    [documentId: string]: DocumentStatus;
};

const initialState: documentProcessingStatusState = {};

export const documentProcessingStatusSlice = createSlice({
    name: 'documentProcessingStatus',
    initialState,
    reducers: {
        updateDocumentProcessingStatus: (state, action) => {
            const { id: documentId, status, step } = action.payload;

            if(status === DOCUMENT_PROCESSING_STATUS.DELETE_COMPLETED) {
                delete state[documentId];
            } else {
                state[documentId] = {
                documentId,
                    status,
                    step,
                };
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDocuments.fulfilled, (state, action) => {
            const documents = action.payload.documents;

            const docs = documents.map((document: Document) => ({
                documentId: document.id,
                status: document.document_processing_status[0]?.status || DOCUMENT_PROCESSING_STATUS.PENDING,
                step: document.document_processing_status[0]?.step || DOCUMENT_PROCESSING_STEP.NOT_STARTED,
            }));
            
            docs.forEach((doc: DocumentStatus) => {
                state[doc.documentId] = doc;
            });
        });

        builder.addCase(fetchWorkspaceDocuments.fulfilled, (state, action) => {
            const documents = action.payload.documents;

            const docs = documents.map((document: Document) => ({
                documentId: document.id,
                status: document.document_processing_status[0]?.status || DOCUMENT_PROCESSING_STATUS.PENDING,
                step: document.document_processing_status[0]?.step || DOCUMENT_PROCESSING_STEP.NOT_STARTED,
            }));

            docs.forEach((doc: DocumentStatus) => {
                state[doc.documentId] = doc;
            });
        });
        builder.addCase(fetchTemplateDocuments.fulfilled, (state, action) => {
            const documents = action.payload.documents;

            const docs = documents.map((document: Document) => ({
                documentId: document.id,
                status: document.document_processing_status[0]?.status || DOCUMENT_PROCESSING_STATUS.PENDING,
                step: document.document_processing_status[0]?.step || DOCUMENT_PROCESSING_STEP.NOT_STARTED,
            }));

            docs.forEach((doc: DocumentStatus) => {
                state[doc.documentId] = doc;
            });
        });
    }
});

export const selectDocumentProcessingStatus = (state: RootState) => Object.values(state.documentProcessingStatus);

const selectDocumentProcessingStatusDocumentId = (state: RootState, documentId: string) => documentId;
export const selectDocumentProcessingStatusByDocumentId = createSelector(
    [selectDocumentProcessingStatus, selectDocumentProcessingStatusDocumentId],
    (documentProcessingStatus, documentId) => documentProcessingStatus.find((doc) => doc.documentId === documentId)
);

const selectCountNonCompletedDocumentsIds = (state: RootState, ids: string[]) => ids
export const selectCountNonCompletedDocumentsByIds = createSelector(
    [selectDocumentProcessingStatus, selectCountNonCompletedDocumentsIds],
    (documentProcessingStatus, documentIds) => documentIds.filter((docId) => documentProcessingStatus.find((doc) => doc.documentId === docId)?.status !== DOCUMENT_PROCESSING_STATUS.COMPLETED).length
);

const selectDocumentStatusNameDocumentId = (state: RootState, documentId: string) => documentId;
export const selectDocumentStatusNameByDocumentId = createSelector(
    [selectDocumentProcessingStatusByDocumentId, selectDocumentStatusNameDocumentId],
    (documentProcessingStatus) => documentProcessingStatus?.status
);

export default documentProcessingStatusSlice.reducer;