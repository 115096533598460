import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../app/store";
import { selectWorkspaceById, deleteWorkspace } from "../../features/workspace/workspaceSlice";
import { logEvent } from "../../services/amplitude";
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'

const WorkspaceHistoryCapsule = ({workspaceId}: {workspaceId: string}) => {
    const workspace = useSelector(selectWorkspaceById(workspaceId));
    const dispatch = useDispatch<AppDispatch>();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if(!workspace) {
        return null;
    }

    const handleDeleteWorkspace = async () => {
        setIsDeleting(true);
        await dispatch(deleteWorkspace({ workspaceId }));
        logEvent('workspace_deleted', { workspaceId, title: workspace.title });
        setIsMenuOpen(false);
        setIsDeleting(false);
    }

    return (
        <div className={`relative flex flex-row justify-between items-center min-h-16 py-4 px-6 rounded-2xl bg-brown-grey-500 w-3/5 min-w-96 text-dark-purple-800 my-2 ${isDeleting ? 'animate-pulse' : ''}`}>
            <div className="text-sm font-medium"> {workspace.title} </div>
            <div className="flex flex-row items-center">
                {isDeleting ? (
                    <div className="mx-4 flex flex-row items-center">
                        <div className="text-xs font-medium"> Deleting... </div>
                    </div>
                ) : (
                <div ref={menuRef} className="relative">
                    <button 
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className={`mx-4 p-1 rounded-full h-4 w-8 bg-brown-grey-700 hover:opacity-100 transition-opacity duration-200 flex items-center justify-center ${isMenuOpen ? 'opacity-100' : 'opacity-50'}`}
                    >
                        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                            <circle cx="5" cy="12" r="2" />
                            <circle cx="12" cy="12" r="2" />
                            <circle cx="19" cy="12" r="2" />
                        </svg>
                    </button>
                    {isMenuOpen && (
                        <div className="absolute right-0 bg-brown-grey-400 shadow-lg rounded-lg py-2 z-10 min-w-[160px]">
                            <button 
                                onClick={handleDeleteWorkspace}
                                className="block w-full font-medium text-xs text-left px-4 py-2 hover:bg-brown-grey-500 whitespace-nowrap"
                            >
                                Delete Workspace
                            </button>
                        </div>
                    )}
                </div>
                )}
                { !isDeleting && (
                    <NavLink className="transition hover:scale-110 w-8 h-8 flex items-center justify-center rounded-full bg-slate-900 px-1" to={`workspace/memo/${workspaceId}`}>
                        <ArrowRight fill='white' width={20}/>
                    </NavLink>
                )}
            </div>
        </div>
    )
}

export default WorkspaceHistoryCapsule;