import { Outlet } from "react-router-dom";

import Chat from "../components/Chat";
import WorkflowCards from "../components/WorkflowCards";
import WorkspaceHistory from "../components/WorkspaceHistory";

const Home = () => {
    return (
        <div className="w-full flex flex-col">
            < Outlet />
        </div>
    );
};

export default Home;