import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import pulseApi from '../../services/pulse-api';

interface Org {
    id: string;
    title: string;
}

interface OrgState {
    org: Org | null;
}

const initialState: OrgState = {
    org: null,
};

export const fetchOrg = createAsyncThunk<any, { orgId: string }>(
    'org/fetchOrg',
    async ({ orgId }) => {
        const data = await pulseApi.get(`/organization/${orgId}`);

        return data;
    }
);

export const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        setOrg: (state, action: PayloadAction<{organization: Org}>) => {
            state.org = action.payload.organization;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrg.fulfilled, (state, action) => {
            state.org = action.payload.organization;
        });
    }
});

export default orgSlice.reducer;