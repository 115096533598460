import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import pulseApi from "../../services/pulse-api";
import { logEvent } from "../../services/amplitude";

type Template = {
  id: string;
  title: string;
  data: any;
  namespace_id: string;
  documents: any[];
};

interface TemplateState {
  templates: Template[];
}

const initialState: TemplateState = {
  templates: [],
};

export const fetchTemplates = createAsyncThunk<any, { namespaceId: string }>(
  "template/fetchTemplates",
  async ({ namespaceId }: { namespaceId: string }) => {
    const query = `namespace_id=${namespaceId}`;
    const data = await pulseApi.get(`/template?${query}`);
    return data;
  }
);

export const createTemplate = createAsyncThunk<
  any,
  { title: string; namespaceId: string }
>(
  "template/createTemplate",
  async ({ title, namespaceId }: { title: string; namespaceId: string }) => {
    const data = await pulseApi.post("/template", {
      title,
      namespace_id: namespaceId,
    });
    logEvent("template_created", {
      template_title: title,
      namespace_id: namespaceId,
    });
    return data;
  }
);

export const createTemplateDocument = createAsyncThunk(
  "template/createTemplateDocument",
  async ({
    templateId,
    documentId,
  }: {
    templateId: string;
    documentId: string;
  }) => {
    const query = `document_id=${documentId}`;
    const data = await pulseApi.post(
      `/template/${templateId}/document?${query}`,
      {}
    );
    logEvent("template_document_created", {
      template_id: templateId,
      document_id: documentId,
    });
    return data;
  }
);

export const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    addTemplate: (state, action: PayloadAction<Template>) => {
      state.templates.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      const templates = action.payload.templates;
      state.templates = templates;
    });
  },
});

export const selectTemplateById =
  (templateId: string) => (state: { template: TemplateState }) =>
    state.template.templates.find((template) => template.id === templateId);

export const selectNameByTemplateId =
  (templateId: string) => (state: { template: TemplateState }) => {
    const template = state.template.templates.find(
      (template) => template.id === templateId
    );
    return template?.title;
  };

export default TemplateSlice.reducer;
