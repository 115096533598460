import React from 'react';
import Modal from '@mui/material/Modal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  isPrivacyPolicy: boolean;
  onClose: () => void;
}

const PRIVACY_POLICY = `
  **Privacy Policy**  
**Last Updated: Mar 20, 2024**

Our Privacy Policy explains how Smart Pulse Inc. collects, stores, uses, discloses, and otherwise processes information about you in the course of our business, including through our websites that link to this notice (such as usepulse.co); the “Pulse Platform,” our software-as-a-service offering; and our marketing and sales activities (collectively, our “Services”). It also sets out important information about your privacy rights.

### PERSONAL INFORMATION WE COLLECT
We collect information that alone or in combination with other information in our possession could be used to identify you (“Personal Information”) as follows:

**Personal Information You Provide:** We may collect Personal Information if you create an account to use our Services or communicate with us.

**Communication Information:** If you communicate with us, we may collect your name, contact information, and the contents of any messages you send (“Communication Information”).

**Payment Information:** When you purchase or subscribe to our Services, we may collect your billing information, such as your credit card number, expiration date, and billing address, or use a third-party payment processor to process your payments (“Payment Information”).

**Personal Information We Receive Automatically From Your Use of Our Services:** When you visit, use, and interact with our Services, we may receive certain information about your visit, use, or interactions (“Technical Information”), including the following:
- **Log Data:** Information that your browser automatically sends whenever you visit the Site (“Log Data”). Log Data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interacted with the Site.
- **Usage Data:** We may automatically collect information about your use of our Services, such as the types of content that you view or engage with, the features you use, and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, computer connection, IP address, and the like.
- **Device Information:** Includes the name of the device, operating system, and browser you are using. Information collected may depend on the type of device you use and its settings.
- **Cookies:** A cookie is a small string of information that websites you visit transfer to your computer for identification purposes. Cookies can be used to follow your activity on the website, and that information helps us to understand your preferences and improve your website experience. Cookies are also used for activities such as remembering your access credentials for our Services. In addition to the cookies used by Smart Pulse Inc., some cookies are placed by third parties, such as Google (for analytics). These third-party cookies are not under our control, so you should check the website of the third-party service providers for more information regarding these cookies. By using our Services, you agree to the use of cookies for the following purposes:
  - **Essential Cookies:** These are essential to enable you to move around the website and use its features, such as accessing secure areas of the website.
  - **Performance and Analytics Cookies:** These include Google Analytics and track the pages you visit and the content you access, so we can determine which content is most popular and improve the performance of our website. These cookies primarily record aggregate and anonymous statistical data but capture a minimal amount of identifiable information such as order IDs.
  - **Functional Cookies:** They remember the choices you make, such as language options or the region you are in. These cookies help make your visit more personal and are deleted automatically when you close your browser or the session expires.

You can turn off all cookies if you prefer not to receive them. You can also have your computer warn you whenever cookies are being used. For both options, you must adjust your browser settings (such as Chrome, Safari, Firefox, Edge, or other browsers). Software products are also available to manage cookies. Please be aware that when you choose to reject cookies, you may limit the features and functionality of our Services.

**Customer Support Information:** When you contact us for customer support, feedback, or inquiries, we may collect your name, email address, phone number, and any other information you provide us or that we request to assist you or resolve your issue (“Support Information”).

**Analytics:** We may use various online analytics products that use cookies to help us analyze how users use our Services and enhance your experience when you use them.

**Online Tracking and Do Not Track Signals:** Our website currently does not respond to “Do Not Track” (“DNT”) signals and operates as described in this Privacy Policy, whether or not a DNT signal is received.

The following disclosures are intended to provide additional information about (1) the categories of Personal Information we collect (as defined above), (2) the source of the Personal Information, (3) how we use each category of Personal Information, and (4) how we disclose Personal Information. These disclosures do not limit our ability to use or disclose information as described above.

| **Category of Personal Information** | **Sources of Personal Information** | **Use of Personal Information** | **Disclosure of Personal Information** |
| ------------------------------------ | ---------------------------------- | ------------------------------ | --------------------------------------- |
| **Payment Information** | We may collect Payment Information from you. | We may use Payment Information to process your payment, including through the use of a third-party payment processor. | We may disclose Payment Information to our Affiliates and third-party payment processors. |
| **Communication Information** | We collect Communication Information directly from you. | We use Communication Information for providing our Services and responding to you. | We disclose Communication Information to our Affiliates and service providers. |
| **Technical Information** | We collect Technical Information from you, including through your interaction with our Services. | We use Technical Information for analytics and, in some cases, for moderation and prevention of fraud and malicious activity. | We disclose Technical Information to our Affiliates and analytics providers. |
| **Support Information** | We may collect Support Information from you in providing your support in your use of the Services. | We use Support Information for providing support, improving the Services, and responding to you. | We may disclose Support Information to our Affiliates. |

### HOW WE USE PERSONAL INFORMATION
We do not sell your Personal Information.

We may use Personal Information for the following purposes:
- To provide, administer, maintain, improve, and/or analyze the Services;
- To provide you with support services;
- To communicate with you, including by discussing options to use the Smart Pulse Platform;
- To develop new features and services;
- To prevent fraud, criminal activity, or misuse of our services, and to ensure the security of our IT systems, architecture, and networks; and
- To comply with legal obligations and legal processes and to protect our rights, privacy, safety, or property, and/or that of our Affiliates, you, or other third parties.

**Aggregated Information:** We may aggregate Personal Information and use the aggregated information to analyze the effectiveness of our Services and improve and add features to our Services. From time to time, we may also analyze the general behavior and characteristics of users of our Services.

### DISCLOSURE OF PERSONAL INFORMATION
In certain circumstances, we may share your Personal Information with third parties without further notice to you, unless required by law, including without limitation in the following situations:

- **Legal Requirements:** If required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, including to meet national security or law enforcement requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in urgent circumstances to protect the personal safety of users of the Services, or the public, or (v) protect against legal liability.

### LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION
Our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it. We will normally collect Personal Information from you only (i) where we have your consent to do so, (ii) where we need the Personal Information to perform a contract with you, (iii) where we need to comply with a legal obligation, or (iv) where the processing is in our legitimate interests (and not overridden by your data protection interests or fundamental rights and freedoms). Our legitimate interests are (a) to prevent fraud, (b) to provide you with access to our Services, and (c) to ensure the security of our Services.

If we ask you to provide Personal Information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not (as well as of the possible consequences if you do not provide your Personal Information). Similarly, if we collect and use your Personal Information in reliance on our legitimate interests (or those of any third party), we will make clear to you at the relevant time what those legitimate interests are.

### YOUR RIGHTS AND CHOICES
Depending on your location, you may have the following rights with respect to your Personal Information:

- **Access:** You have the right to request confirmation of whether we process Personal Information relating to you, and if so, to request a copy of that Personal Information.
- **Rectification:** You have the right to request that we rectify or update any of your Personal Information that is inaccurate or incomplete.
- **Erasure:** You have the right to request that we erase your Personal Information in certain circumstances, such as where it is no longer necessary for the purposes for which it was originally collected.
- **Restriction of Processing:** You have the right to request that we restrict the processing of your Personal Information under certain circumstances.
- **Data Portability:** You have the right to request a copy of your Personal Information that you have provided to us in a structured, commonly used, and machine-readable format and to transfer that information to another controller, where technically feasible.
- **Objection:** You have the right to object to the processing of your Personal Information under certain circumstances, such as where we process your information based on our legitimate interests.

To exercise your rights, you may contact us at the contact information provided below. We will respond to your request in accordance with applicable laws.

### DATA RETENTION
We retain Personal Information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested, to comply with applicable legal, tax, or accounting requirements, or to resolve disputes or enforce our agreements). When we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymize it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.

### DATA TRANSFERS
We may transfer your Personal Information to countries other than the country in which you reside, including to the United States, where we and our service providers operate. We rely on European Commission adequacy decisions or other lawful transfer mechanisms to transfer data internationally.

### CHILDREN’S PRIVACY
Our Services are not intended for children under the age of 13 (or other age as required by local law), and we do not knowingly collect Personal Information from children. If we become aware that a child has provided us with Personal Information, we will take steps to delete such information from our files as soon as possible.

### LINKS TO OTHER WEBSITES
The Services may contain links to third-party websites. We do not control these websites, and we are not responsible for their privacy policies or practices. This Privacy Policy does not apply to third-party websites, so we encourage you to review the privacy policies of those third-party websites before providing any Personal Information.

### CHANGES TO THIS PRIVACY POLICY
We may update this Privacy Policy from time to time in response to changing legal, technical, or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws. You can see when this Privacy Policy was last updated by checking the “last updated” date displayed at the top of this Privacy Policy.

### HOW TO CONTACT US
If you have any questions or concerns about our use of your Personal Information, please contact us at:
- **Email:** contact@usepulse.co  
  `;

const TERMS_OF_SERVICE = `
**Service Terms**  
**Last Updated: Mar 6, 2024**

These Service Terms are part of Your Terms with Smart Pulse Inc. Any capitalized terms used but not defined in these Service Terms have the meaning set forth in the Terms. In any situation in which there is a conflict between these Service Terms and the Terms, these Service Terms control with respect to features and services governed by these Service Terms.

### PREVIEW FEATURES
This section governs Your use of services or features that Smart Pulse Inc. offers on a preview, beta, or early access basis (“Preview Features”). Any Feedback related to the Preview Features may be used to improve the Service.

Preview Features are offered on an as-is and as-available basis. Your access to or use of Preview Features is at your own risk. To the extent permitted by law, Smart Pulse Inc. expressly disclaims all, and You receive no, warranties and conditions of any kind, whether express or implied, including but not limited to, those of merchantability, satisfactory quality, title, fitness for a particular purpose, and non-infringement.

Unless indicated otherwise in these Service Terms, Preview Features will be metered and billed as normal usage of the Service.

Smart Pulse Inc. may materially change Preview Features during the course of the preview and may opt to discontinue Preview Features.

### DATA STORAGE LOCATION
By default, Customer Data is stored in the United States.

Where supported by our underlying cloud service provider(s) and where otherwise in compliance with applicable laws and regulations, You may request to have your Customer Data stored outside of the United States, and Smart Pulse Inc. will use commercially reasonable efforts to support such requests.

You acknowledge that storing Customer Data outside the United States may affect the performance and availability of the Services.

You further acknowledge that processing of Customer Data will still occur within the United States.

### TARGETED SOURCE FEATURES
The use of certain services and features either requires You to ingest documents into the Smart Pulse Platform or relies on the Smart Pulse Platform’s ability to target specific exogenous data sources (such as publicly available web sources) (“Targeted Source Features”).

Documents provided by the authorized user and ingested by the Service are specific and isolated to the user ingesting the documents and remain queryable and accessible by the authorized user only. All such ingested documents are Customer Data.

The Targeted Source Features are part of the Basic Service.
`;

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ isOpen, isPrivacyPolicy, onClose }) => {
  const markdownContent = isPrivacyPolicy ? PRIVACY_POLICY : TERMS_OF_SERVICE;

  const title = isPrivacyPolicy ? 'Privacy Policy' : 'Service Terms';

  return (
    <Modal
    className="w-full h-full"
    open={isOpen}
    onClose={onClose}
    aria-labelledby="privacy-policy-modal"
    aria-describedby="privacy-policy-description">
      <div className="bg-white rounded-lg shadow-lg p-12 m-4 w-3/4 h-3/4 mx-auto mt-24 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <div className="markdown-body">
          <ReactMarkdown 
            className="w-full h-full"
            children={markdownContent} 
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{ p: ({ children }) =><p className="mb-6">{children}</p> }} 
          />
        </div>
        <button className="mt-4 text-blue-600 underline text-lg" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModal;