import msgpack from 'msgpack-lite';

interface WebSocketOptions {
    url: string;
    token: string;
    heartbeatInterval?: number; 
    maxRetries?: number;
    retryInterval?: number;
    jitterRange?: number;
}

interface CustomMessageEvent {
    event: string;
    data?: any;
}

type EventCallback = (data: any) => void;

enum WebSocketState {
    DISCONNECTED,
    CONNECTING,
    CONNECTED,
    RECONNECTING
}

export class WebSocketClient {
    private ws!: WebSocket;
    private url: string;
    private token: string;
    private eventListeners: Map<string, EventCallback[]> = new Map();
    private heartbeatInterval: number;
    private heartbeatTimeoutId?: number;
    private retryCount: number = 0;
    private maxRetries: number;
    private reconnectTimeoutId?: number;
    private state: WebSocketState = WebSocketState.DISCONNECTED;
    private retryInterval: number;
    private jitterRange: number;

    constructor(options: WebSocketOptions) {
        this.url = options.url;
        this.token = options.token;
        this.heartbeatInterval = options.heartbeatInterval || 15000;
        this.maxRetries = options.maxRetries || 30;
        this.retryInterval = options.retryInterval || 3000;
        this.jitterRange = options.jitterRange || 2000;
        this.connect();
    }

    private connect() {
        console.log('Attempting to connect to WebSocket...');
        this.state = WebSocketState.CONNECTING;
        this.ws = new WebSocket(this.url);

        this.ws.onopen = this.authenticate.bind(this);
        this.ws.onmessage = this.handleMessage.bind(this);
        this.ws.onclose = this.handleClose.bind(this);
        this.ws.onerror = this.handleError.bind(this);
    }

    private authenticate() {
        console.log('WebSocket connection opened, attempting to authenticate...');
        this.send('auth', { token: this.token });
    }

    private handleMessage(event: MessageEvent) {
        this.resetHeartbeat();

        const reader = new FileReader();
        reader.onload = () => {
            const dataBuffer = new Uint8Array(reader.result as ArrayBuffer);
            const decoded = msgpack.decode(dataBuffer);
            const { event: eventType, data: eventData } = decoded;

            if (eventType === 'pong') {
                console.log('Received pong from server');
                return;
            }

            if (eventType === 'auth') {
                this.handleSuccessfulAuth();
                return;
            }

            const listeners = this.eventListeners.get(eventType);
            if (listeners) {
                listeners.forEach(callback => callback(eventData));
            }
        };
        reader.readAsArrayBuffer(event.data as Blob);
    }

    private handleClose() {
        console.log('WebSocket connection closed.');
        this.state = WebSocketState.DISCONNECTED; // Update state to disconnected
        this.stopHeartbeat();
        this.retryConnection();
    }

    private handleError(error: Event) {
        console.error('WebSocket encountered an error:', error);
        this.state = WebSocketState.DISCONNECTED; // Update state to disconnected on error
        this.stopHeartbeat();
        this.retryConnection();
    }

    private handleSuccessfulAuth() {
        console.log('WebSocket authenticated successfully.');
        this.state = WebSocketState.CONNECTED; // Update state to connected
        this.startHeartbeat();
        this.retryCount = 0; // Reset retry count on successful connection
    }

    private retryConnection() {
        if (this.retryCount < this.maxRetries) {
            const jitter = Math.random() * this.jitterRange;
            const delay = this.retryInterval + jitter;
            console.log(`Attempting to reconnect in ${delay / 1000} seconds...`);
            this.state = WebSocketState.RECONNECTING;
            this.retryCount++;

            this.reconnectTimeoutId = window.setTimeout(() => {
                console.log('Reconnecting now...');
                this.connect();
            }, delay);
        } else {
            console.error('Max retries reached. Could not reconnect to WebSocket.');
            this.state = WebSocketState.DISCONNECTED; // Set state to disconnected after max retries
        }
    }

    public on(event: string, callback: (data: any) => void) {
        if (!this.eventListeners.has(event)) {
            this.eventListeners.set(event, []);
        }
        this.eventListeners.get(event)?.push(callback);
    }

    public send(event: string, data: any) {
        const message: CustomMessageEvent = { event, data };
        const encoded = msgpack.encode(message);
        try {
            this.ws.send(encoded);
        } catch (error) {
            console.error('Failed to send WebSocket message:', error);
        }
    }

    private startHeartbeat() {
        this.heartbeatTimeoutId = window.setTimeout(() => {
            this.send('ping', {});
            console.log('Sent ping to server');
            this.startHeartbeat(); // Schedule the next heartbeat
        }, this.heartbeatInterval);
    }

    private resetHeartbeat() {
        if (this.heartbeatTimeoutId) {
            window.clearTimeout(this.heartbeatTimeoutId);
            this.startHeartbeat();
        }
    }

    private stopHeartbeat() {
        if (this.heartbeatTimeoutId) {
            window.clearTimeout(this.heartbeatTimeoutId);
        }
        if (this.reconnectTimeoutId) {
            window.clearTimeout(this.reconnectTimeoutId);
        }
    }
}
