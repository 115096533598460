import * as amplitude from "@amplitude/analytics-browser";


const env = process.env.NODE_ENV;
amplitude.init("740c86048263457c24b254633824ced4");

export const setCurrentUserId = (userId: string) => {
  if(env !== "development") {
    amplitude.setUserId(userId);
    return;
  }

  const shortUserId = userId.slice(0, 8);
  const devUserId = `dev_${shortUserId}`;
  amplitude.setUserId(devUserId)
};

export const logEvent = (eventName: string, eventProperties?: any) => {
  const props = {
    ...eventProperties,
    env,
  };

  amplitude.logEvent(eventName, props);
};

export const reset = () => {
  amplitude.reset();
};

// New function to set custom user properties using Amplitude Identify API
export const setUserProperties = (properties: {
  email?: string;
  firstName?: string;
  lastName?: string;
}) => {
  const identify = new amplitude.Identify();
  if (properties.email) identify.set("email", properties.email);
  if (properties.firstName) identify.set("first_name", properties.firstName);
  if (properties.lastName) identify.set("last_name", properties.lastName);

  amplitude.identify(identify);
};
