import { configureStore } from '@reduxjs/toolkit';

import userReducer from '../features/user/userSlice';
import chatReducer from '../features/chat/chatSlice';
import messageReducer from '../features/message/messageSlice';
import ordReducer from '../features/org/orgSlice';
import templateReducer from '../features/template/templateSlice';
import namespaceReducer from '../features/namespace/namespaceSlice';
import workspaceReducer from '../features/workspace/workspaceSlice';
import workspaceMemoReducer from '../features/workspace-memo/workspaceMemoSlice';
import documentReducer from '../features/document/documentSlice';
import documentProcessingStatusReducer from '../features/document/documentProcessingStatusSlice';
import workspaceMemoSectionReducer from '../features/workspace-memo-section/workspaceMemoSectionSlice';

import realtimeMiddleware from '../middleware/realtime'; 

export const store = configureStore({
  reducer: {
    user: userReducer,
    chat: chatReducer,
    message: messageReducer,
    org: ordReducer,
    document: documentReducer,
    template: templateReducer,
    namespace: namespaceReducer,
    workspace: workspaceReducer,
    workspaceMemo: workspaceMemoReducer,
    documentProcessingStatus: documentProcessingStatusReducer,
    workspaceMemoSection: workspaceMemoSectionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(realtimeMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
