import { NavLink } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '../../assets/home-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import style from './styles.module.scss';

const MenuItem = ({ to, Icon, label }: {to: string, Icon: any, label: string}) => (
    <li>
        <NavLink 
            to={to} 
            className={({ isActive }) => 
                `text-sm px-6 py-3 mb-2 flex flex-row items-center ${isActive ? 'text-white font-semibold border-l-4 border-l-purple-700' : 'text-gray-300 border-l-4 border-l-transparent'}`
            }
        >
            <Icon className="w-5 h-5 inline-block" />
            <span className='px-4'>{label}</span>
        </NavLink>
    </li>
);

const NavSideBar = () => {
    return (
        <div className={`w-56 h-full rounded-2xl bg-slate-400 ${style.navbar}`}>
            <ul className='pt-20'>
                <MenuItem to="/home" Icon={HomeIcon} label="Home" />
                <MenuItem to="/query" Icon={SearchIcon} label="Query" />
            </ul>
        </div>
    );
};

export default NavSideBar;
