import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import PulseLoader from "react-spinners/PulseLoader";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as RepeatIcon} from '../../assets/repeat-icon.svg';
import { AppDispatch, RootState } from '../../app/store';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { selectWorkspaceDocuments, Document } from '../../features/document/documentSlice';
import { selectCountNonCompletedDocumentsByIds } from '../../features/document/documentProcessingStatusSlice';
import { selectDocumentIdsByTemplateId, fetchTemplateDocuments } from '../../features/document/documentSlice';
import { selectNameByTemplateId } from '../../features/template/templateSlice';
import { selectSectionsByMemoId } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';
import { fetchWorkspaceMemos, selectMostRecentMemoByWorkspaceId, createWorkspaceMemo } from '../../features/workspace-memo/workspaceMemoSlice';
import { fetchWorkspaceMemoSections } from '../../features/workspace-memo-section/workspaceMemoSectionSlice';
import MemoTemplatePicker from '../MemoTemplatePicker';
import MarkdownToWord from '../MarkdownToWord';
import { use } from 'marked';

const isDebugMode = localStorage.getItem('pulse-app-debug') === 'true';

const MemoGenDraft = () => {
    const dispatch: AppDispatch = useDispatch();
    const { id: workspaceId = "" } = useParams();
    const memo = useSelector(selectMostRecentMemoByWorkspaceId(workspaceId));
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const [selectedTemplateId, setCurrentTemplateId] = useState<string | undefined>(memo?.template_id);
    const templateDocumentIds = useSelector(selectDocumentIdsByTemplateId(selectedTemplateId || ''));
    const memoSections = useSelector(selectSectionsByMemoId(memo?.id || ''));
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState<string>('');

    const workspaceDocuments = useSelector(selectWorkspaceDocuments(workspaceId));
    const workspaceDocIds = workspaceDocuments.map((doc: Document) => doc.id);

    const validDocument = workspaceDocuments.length > 0;
    const nonCompletedDocsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, workspaceDocIds));
    const unCompletedTemplateDocsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, templateDocumentIds));
    const templateName = useSelector(selectNameByTemplateId(selectedTemplateId || ''));
    
    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspaceMemos({ workspaceId }));
            setCurrentTemplateId(memo?.template_id);
        }
    }, [dispatch, workspaceId, memo?.template_id]);

    useEffect(() => {
        const memoId = memo?.id;
        if(memoId) {
            dispatch(fetchWorkspaceMemoSections({ workspaceId, workspaceMemoId: memoId }));
        }
    }, [dispatch, workspaceId, memo?.id]);

    useEffect(() => {
        if(selectedTemplateId && currentNamespaceId) {
            dispatch(fetchTemplateDocuments({ namespaceId: currentNamespaceId, templateId: selectedTemplateId }));
        }
    }, [dispatch, selectedTemplateId, currentNamespaceId]);

    const onRepeatClick = async () => {
        if (workspaceId && selectedTemplateId) {
            await dispatch(createWorkspaceMemo({ workspaceId, templateId: selectedTemplateId }));
            dispatch(fetchWorkspaceMemos({ workspaceId }));
        }
    }
    const templatesDocsReady = selectedTemplateId && templateDocumentIds.length > 0 && unCompletedTemplateDocsCount === 0;
    const canRepeat = nonCompletedDocsCount === 0 && selectedTemplateId && validDocument && (workspaceId !== 'new' || memo?.draft_processing_status === 'COMPLETED') && templatesDocsReady;
    const memoName = 'Memo Draft';
    let memoTitleContent = <div className='flex flex-row items-center'> 
        {memoName}
        <button 
            className={`ml-2 h-7 w-7 text-gray-500 rounded-full bg-dark-purple-800 flex justify-center items-center ${!canRepeat ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'}`}
            disabled={!canRepeat}
            onClick={onRepeatClick}
            >
            <RepeatIcon className='h-5 fill-white'/>
        </button>
    </div>

    const hasAllSectionDrafts = memoSections.length > 0 && memoSections.every((section) => section.draft);
    const memoHeaderContent = <div className='flex flex-row w-full'>
        <div className='flex flex-col w-full justify-between'>
            <div className='flex flex-row items-center'> 
                {memoTitleContent}
            </div>
            {/* If draft status is not completed, show loading spinner */}
            {memo && !hasAllSectionDrafts && <div className="flex flex-col text-gray-500">
                    <p>Generating memo draft</p>
                    <PulseLoader
                        size="4"
                        color="#27174B"
                        speedMultiplier={1.2}
                    />
                </div>
            }
        </div>
        <div className='w-60'>
            <MemoTemplatePicker workspaceId={workspaceId} onSelect={(templateId: string) => { setCurrentTemplateId(templateId) }} />
        </div>
    </div>

    let memoContent = <div className='text-gray-400 text-sm'> Your draft will appear here once generated. </div>;
    let downloadButtonContent;
    
    if(selectedTemplateId && templateDocumentIds.length === 0) { 
        memoContent = <div className='text-gray-400 text-sm'> The selected template: {templateName} does not have any documents.<br/>
        <NavLink
            to={`?modal=manage-templates&modal_template_id=${selectedTemplateId}`}
            className='text-grey-500 font-semibold underline'
        > Add documents to the template
        </NavLink>
        &nbsp;or select another template.
        </div>;
    } else if(unCompletedTemplateDocsCount > 0) {
        memoContent = <div className='text-gray-400 text-sm'> Some documents for the selected template are still being processed, please wait... </div>;
    }

    // if(memo && memo.draft_processing_status === 'COMPLETED') {
    //     memoContent = 
    //     <div className="text-md w-full lg:prose-sm">
    //         <ReactMarkdown
    //             className="w-full"
    //             remarkPlugins={[remarkGfm]}
    //             rehypePlugins={[rehypeRaw]}
    //         >{memo.data.memo_draft}</ReactMarkdown>
    //     </div>
    //     downloadButtonContent = <div className='absolute bottom-0 right-0'>
    //         <MarkdownToWord memoId={memo.id} />
    //     </div>
    // }

    const handleOpen = (content: string) => {
        if(isDebugMode) {
            setModalContent(content); // Set the content for the modal
            setOpen(true); // Open the modal
        }
    };

    const handleClose = () => {
        setOpen(false); // Close the modal
    };

    if(memoSections.length > 0) {
        if (isDebugMode) {
            memoSections.forEach((section) => {
                if(section.data){
                    console.log(`-------------------------- Section ${section.title} Q & A Start --------------------------`);
                    console.log(section.data.raw_draft_context);
                    console.log(`-------------------------- Section ${section.title} Q & A End --------------------------`);
                }
            });
        }
 
        memoContent = (
            <div className="text-md w-full prose">
                {memoSections.map((section, idx) => {
                    const tooltipContent = section.data ?`
                        ------------ Q & A ------------
                        ${section.data.raw_draft_context}
                        
                        ------------ Format ------------
                        ${section.data.format}
                    `: 'Unavailable';
    
                    return (
                        <div
                            key={idx}
                            className="cursor-pointer"
                            onClick={() => handleOpen(tooltipContent)}
                        >
                            <ReactMarkdown
                                className="w-full markdown"
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                            >
                                {`${section.draft}`}
                            </ReactMarkdown>
                        </div>
                    );
                })}
            </div>
        );

    }

    return (
        <div className='flex flex-row justify-between items-center p-4 px-6 mr-10 bg-brown-grey-500 rounded-xl w-full'>
            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                       Section Debug Info
                    </Typography>
                    <Typography
                        id="modal-description"
                        sx={{ height: '500px', overflowY: 'scroll' }}
                    >
                        <pre>{modalContent}</pre>
                    </Typography>
                </Box>
            </Modal>
            <div className='flex flex-col w-full relative'>
            <div className='font-semibold w-full'>
                {memoHeaderContent}
            </div>
            <div className='pt-6 overflow-hidden overflow-y-scroll' style={{ height: '60vh' }}>
                {memoContent}
            </div>
            <div className='flex flex-row items-center'></div>
                {downloadButtonContent}
            </div>
        </div>
    );
}

export default MemoGenDraft;