/**
 * TemplateDocuments Component - This lists all the documents in a template
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { AppDispatch, RootState } from '../../app/store';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { createDocuments, fetchTemplateDocuments, selectTemplateDocumentIds } from '../../features/document/documentSlice';
import { createTemplateDocument } from '../../features/template/templateSlice';
import DocumentFileCapsule from '../DocumentFileCapsule';
import DocumentFilePicker from '../DocumentFilePicker';

const TemplateDocuments = () => {
    const dispatch: AppDispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { id: workspaceId = "" } = useParams();
    const templateId = searchParams.get('modal_template_id') || "";
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const docIds = useSelector((state: RootState) => selectTemplateDocumentIds(state, templateId));

    useEffect(() => {
        if(currentNamespaceId && workspaceId && templateId) {
            dispatch(fetchTemplateDocuments({namespaceId: currentNamespaceId, templateId}));
        }
    }, [dispatch, workspaceId, templateId, currentNamespaceId]);

    if(!currentNamespaceId) {
        return null;
    }

    const onUploaded = async (fileId: string) => {
        const { documents } = await dispatch(createDocuments({namespaceId: currentNamespaceId, fileIds: [fileId]})).unwrap();
        const promises = documents.map(async (doc: any) => {
            await dispatch(createTemplateDocument({templateId, documentId: doc.id}));
        });
        await Promise.all(promises);
    }

    return (
        <div className='my-10'>
            <DocumentFilePicker title="Add Template Documents" onUploaded={onUploaded}/>
            <div className='max-h-60 overflow-x-hidden overflow-y-scroll'>
                {docIds.map((docId) => (
                    <DocumentFileCapsule key={docId} documentId={docId} />
                ))}
            </div>
        </div>
    );
}

export default TemplateDocuments;