import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState, AppDispatch } from "../../app/store";
import { fetchTemplates, selectTemplateById } from "../../features/template/templateSlice";
import { selectCurrentNamespace } from "../../features/namespace/namespaceSlice";
import { selectMostRecentMemoByWorkspaceId } from "../../features/workspace-memo/workspaceMemoSlice";
import ManageTemplateModal from "../ManageTemplateModal";

const MemoTemplatePicker = ({workspaceId, onSelect}: {workspaceId: string, onSelect: (templateId: string) => void}) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const namespaceId = useSelector((state: RootState) => selectCurrentNamespace(state)?.id);
    const memo = useSelector(selectMostRecentMemoByWorkspaceId(workspaceId));
    const templates = useSelector((state: RootState) => state.template.templates);
    const [isOpen, setIsOpen] = useState(false);
    const [templateId, setTemplateId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (memo?.template_id) {
            setTemplateId(memo.template_id);
        }

        if (namespaceId){
            dispatch(fetchTemplates({ namespaceId }));
        }
    }, [dispatch, namespaceId, memo]);

    const template = useSelector(selectTemplateById(templateId || ''));
    const templateName = template?.title;

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleTemplateSelect = (templateId: string) => {
        setTemplateId(templateId);
        setIsOpen(false);
        onSelect(templateId);
    };

    const handleCreateNewClick = () => {
        const query = new URLSearchParams(window.location.search);
        query.set("modal_template_id", "new");
        query.set("modal", "manage-templates");
        navigate({ search: query.toString() });
        setIsOpen(false);
    };

    const templateList = templates.map((template) => (
        <div
            key={template.id}
            className="px-4 py-2 hover:bg-brown-grey-700 cursor-pointer text-sm font-normal"
            onClick={() => handleTemplateSelect(template.id)}
        >
            {template.title}
        </div>
    ));

    return (
        <div className="relative inline-block text-left w-full">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-xl border border-gray-300 px-4 py-2 bg-brown-grey-500 text-sm font-medium text-gray-700 hover:bg-brown-grey-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brown-grey-500 focus:ring-brown-grey-700"
                    id="menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                    onClick={handleDropdownToggle}
                >
                    {templateName ? templateName : "Choose Style"}
                    <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 011 1v8.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L9 12.586V4a1 1 0 011-1z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div
                    className="max-h-72 overflow-y-scroll origin-top-right absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-brown-grey-500 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex={-1}
                >
                    <div className="py-1" role="none">
                        <div
                            className="px-4 py-2 text-gray-700 text-sm hover:bg-brown-grey-700 cursor-pointer"
                            onClick={handleCreateNewClick}
                        >
                            Edit Styles
                        </div>
                        {templateList}
                    </div>
                </div>
            )}

            <ManageTemplateModal />
        </div>
    );
};

export default MemoTemplatePicker;
