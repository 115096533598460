import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import pulseApi from "../../services/pulse-api";
import { logEvent } from "../../services/amplitude";

enum DRAFT_PROCESSING_STATUS {
  DRAFT_REQUESTED = "DRAFT_REQUESTED",
  DRAFT_GENERATION_IN_PROGRESS = "DRAFT_GENERATION_IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

const mockWorkspaceMemos = [
  {
    id: "1",
    template_id: "1",
    workspace_id: "1",
    data: {
      memo_draft: "# Memo Draft",
    },
    draft_processing_status: DRAFT_PROCESSING_STATUS.DRAFT_REQUESTED,
    created_at: "2024-08-05T17:33:34.611Z",
  },
];

export type WorkspaceMemo = {
  id: string;
  template_id: string;
  workspace_id: string;
  data: {
    memo_draft: string;
  };
  draft_processing_status: DRAFT_PROCESSING_STATUS;
  created_at: string;
};

interface WorkspaceMemoState {
  workspaceMemos: WorkspaceMemo[];
}

const initialState: WorkspaceMemoState = {
  workspaceMemos: [],
};

export const fetchWorkspaceMemos = createAsyncThunk(
  "workspaceMemo/fetchWorkspaceMemos",
  async ({ workspaceId }: { workspaceId: string }) => {
    const data = await pulseApi.get(`/workspace/${workspaceId}/memo`);
    return data;
  }
);

export const createWorkspaceMemo = createAsyncThunk(
  "workspaceMemo/createWorkspaceMemo",
  async ({
    workspaceId,
    templateId,
  }: {
    workspaceId: string;
    templateId: string;
  }) => {
    const query = `template_id=${templateId}`;
    const data = await pulseApi.post(
      `/workspace/${workspaceId}/memo?${query}`,
      {}
    );
    logEvent("memo_created", {
      workspace_id: workspaceId,
      template_id: templateId,
    });
    return data;
  }
);

export const workspaceMemoSlice = createSlice({
  name: 'workspaceMemo',
  initialState,
  reducers: {
    updateWorkspaceMemo: (state, action: PayloadAction<WorkspaceMemo>) => {
      const updatedMemo = action.payload;
      const index = state.workspaceMemos.findIndex(memo => memo.id === updatedMemo.id);
      if (index === -1) {
        state.workspaceMemos.push(updatedMemo);
        return;
      }
      state.workspaceMemos[index] = {
        ...state.workspaceMemos[index],
        ...updatedMemo,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkspaceMemos.fulfilled, (state, action) => {
      const memos = action.payload.memos;
      state.workspaceMemos = memos;
    });
  },
});

export const selectMostRecentMemoByWorkspaceId =
  (workspaceId: string) => (state: { workspaceMemo: WorkspaceMemoState }) => {
    const memos = state.workspaceMemo.workspaceMemos.filter(
      (memo) => memo.workspace_id === workspaceId
    );
    return memos.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];
  };

export const selectMemoById =
  (memoId: string) => (state: { workspaceMemo: WorkspaceMemoState }) => {
    return state.workspaceMemo.workspaceMemos.find(
      (memo) => memo.id === memoId
    );
  };

export const convertMarkdownToWord = createAsyncThunk(
  "workspaceMemo/convertMarkdownToWord",
  async ({ markdownContent }: { markdownContent: string }) => {
    logEvent("converting_markdown_to_word", {
      message: "converting markdown content to word",
    });
    const data = await pulseApi.post("/convert-to-docx", { markdownContent }, { responseType: "arraybuffer" });
    logEvent("markdown_to_word_converted", {
      message: "markdown content converted to word",
    });
    return data;
  }
);

export default workspaceMemoSlice.reducer;
