// import MemoGenWorkflowCard from '../MemoGenWorkflowCard';
import ReportGenWorkflowCard from '../ReportGenWorkflowCard';

const WorkflowCards = () => {
    return (
        <div className='w-full pt-20 flex flex-col'>
            <h3 className='font-semibold text-lg'> Workflow Apps </h3>
            <div className='pt-4 flex flex-row'>
                {/* <MemoGenWorkflowCard/>     */}
                <ReportGenWorkflowCard/>
            </div>
        </div>
    )
}

export default WorkflowCards;