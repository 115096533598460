/**
 * Document File Picker Component - This component is used to pick a file from the user's device, upload to the server and calls an onUploaded callback function with the uploaded file_id.
 */

import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

import { AppDispatch } from '../../app/store';
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import { uploadFiles } from "../../features/document/documentSlice";
import { logEvent } from "../../services/amplitude";

interface DocumentFilePickerProps {
  title: string;
  onUploaded: (fileId: string) => Promise<void>;
}

interface DocumentFile {
    name: string;
    id: string;
    file?: File;
}

const SUPPORTED_FILE_TYPES = [
    [ "application/msword", "doc" ],
    [ "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx" ],
    [ "application/vnd.ms-powerpoint", "ppt" ],
    [ "application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx" ],
    [ "text/plain", "txt" ],
    [ "application/pdf", "pdf" ],
];

// E.G ".doc, .docx, .ppt, .pptx, .txt, .pdf, .xls, .xlsx"
const SUPPORTED_FILE_TYPE_EXTENSION_STRING = SUPPORTED_FILE_TYPES.map(([_, ext]) => `.${ext}`).join(", ");

const DocumentFilePicker: React.FC<DocumentFilePickerProps> = ({ title, onUploaded }) => {
    const dispatch: AppDispatch = useDispatch();
    const [files, setFiles] = useState<DocumentFile[]>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the input value to allow re-selection of the same file
        }
    }, [files]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Verify that the file is supported
        const selectedFiles = Array.from(event.target.files || []).map((file) => {
            const fileType = file.type;
            const fileExtension = file.name.split('.').pop();
            const supportedFileType = SUPPORTED_FILE_TYPES.find(([type, ext]) => type === fileType || ext === fileExtension);
            let isSupported = true;
            if (!supportedFileType) {
                isSupported = false;
            }
            return {
                name: file.name,
                id: URL.createObjectURL(file),
                file,
                isSupported,
            }
        });

        selectedFiles.forEach((file) => {
            logEvent("file_selected", {
                file_name: file.name,
                file_type: file.file?.type,
                is_supported: file.isSupported,
            });
        });

        const supportedFiles = selectedFiles.filter((file) => file.isSupported);

        const currentFiles = [...files, ...supportedFiles];
        setFiles(currentFiles);

        const uploadPromises = await Promise.all(supportedFiles.map(async (file) => {
            const { file_ids: [fileId] } = await dispatch(uploadFiles({ file: file.file })).unwrap();
            await onUploaded(fileId);
            setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
        }));

        await Promise.all(uploadPromises);
    };

    return (
        <div className={`max-w-md rounded-lg w-64`}>
            <h3 className={`text-md font-semibold mb-2`}>{title}</h3>
            <div className="relative flex items-center w-full h-12 rounded-t-xl">
                <label className="relative w-6 h-6 rounded-lg bg-brown-grey-700 flex justify-center items-center cursor-pointer">
                <input
                    ref={fileInputRef}
                    type="file"
                    accept={SUPPORTED_FILE_TYPE_EXTENSION_STRING}
                    multiple
                    onChange={handleFileChange}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <PlusIcon width={18} stroke="orange" opacity={0.2} />
                </label>
            </div>
            <ul className="max-h-32 overflow-x-hidden overflow-y-scroll">
                {files.map((file) => (
                    <li title={file.name} key={file.id} className="flex flex-row items-center py-1 my-1">
                        <ClipLoader className='mr-2 opacity-40' size={10} speedMultiplier={1.5} color='#27174B' />
                        <span className="w-48 text-xs font-medium text-gray-500 truncate">{file.name}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DocumentFilePicker;