import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../app/store';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { createDocuments, createDocumentIndex, fetchDocuments, selectNonTemplateDocumentIds, selectNonTemplateDocuments } from '../../features/document/documentSlice';
import DocumentFileCapsule from '../DocumentFileCapsule';
import DocumentFilePicker from '../DocumentFilePicker';

const DocumentUpload = () => {
    const dispatch = useDispatch<AppDispatch>();
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const docIds = useSelector((state: RootState) => selectNonTemplateDocumentIds(state));

    // Fetch documents
    useEffect(() => {
        if (currentNamespaceId) {
            dispatch(fetchDocuments({ namespaceId: currentNamespaceId }));
        }
    }, [currentNamespaceId, dispatch]);

    if (!currentNamespaceId) {
        return null;
    }

    const onUploaded = async (fileId: string) => {
        const { documents } = await dispatch(createDocuments({ namespaceId: currentNamespaceId, fileIds: [fileId] })).unwrap();
        const promises = documents.map(async (doc: any) => {
            await dispatch(createDocumentIndex({ namespaceId: currentNamespaceId, documentId: doc.id }));
        });
        await Promise.all(promises);
    }

    return (
        <div className='mr-10'>
            <DocumentFilePicker title="" onUploaded={onUploaded} />
            <div className='max-h-96 overflow-x-hidden overflow-y-scroll'>
                {docIds.map((docId) => (
                    <DocumentFileCapsule key={docId} documentId={docId} />
                ))}
            </div>
        </div>
    );
}

export default DocumentUpload;