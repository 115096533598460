import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

import styles from "./styles.module.scss";
import pulseLogo from "../../assets/pulse-logo.png";
import { AppDispatch } from '../../app/store';
import pulseAPI from '../../services/pulse-api';
import '../../services/pulse-websocket';
import { fetchOrg } from "../../features/org/orgSlice";
import { fetchNamespaces } from "../../features/namespace/namespaceSlice";
import { fetchCurrentUser } from "../../features/user/userSlice";
import NavSideBar from "../NavSideBar";

const Layout = () => {
    const dispatch: AppDispatch = useDispatch();
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const { user } = useSelector((state: any) => state.user);
    const { org } = useSelector((state: any) => state.org);
    
    useEffect(() => {
        const authStatus = pulseAPI.isAuthenticated();
        setIsAuthenticated(authStatus);

        if(!user) {
            dispatch(fetchCurrentUser());
            return;
        };

        dispatch(fetchNamespaces({orgId: user.organization_id}));
        dispatch(fetchOrg({ orgId: user.organization_id }));
    }, [user, isAuthenticated]);

    if(!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
        <div className={`w-full h-lvh flex flex-row justify-around ${styles.layout} overflow-x-scroll`}>
            <div className="flex px-10 flex-col">
                <div className="h-32 flex items-center">
                    <img src={pulseLogo} alt="Pulse Logo" className="h-3" />
                </div>
                <NavSideBar />
                <div className="py-14">
                    <div className="flex items-center py-2 px-4">
                        <div className="bg-purple-600 w-14 h-14 rounded-full flex justify-center items-center">
                            <span className="text-white font-bold"> {user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)} </span>
                        </div>
                        <div className="ml-4">
                            <p className="font-semibold text-gray-700">{user?.first_name} {user?.last_name}</p>
                            <p className="text-sm text-gray-500">{org?.title}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1">
                <Outlet />
            </div>
        </div>
    );
}

export default Layout;