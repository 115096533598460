/**
 * WorkspaceDocuments Component - This lists all the documents in a workspace
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppDispatch } from '../../app/store';
import { selectCurrentNamespaceId } from '../../features/namespace/namespaceSlice';
import { createDocuments, fetchWorkspaceDocuments, selectWorkspaceDocumentIds } from '../../features/document/documentSlice';
import { createWorkspaceDocument } from '../../features/workspace/workspaceSlice';
import DocumentFileCapsule from '../DocumentFileCapsule';
import DocumentFilePicker from '../DocumentFilePicker';

const WorkspaceDocuments = () => {
    const dispatch: AppDispatch = useDispatch();
    const { id: workspaceId = "" } = useParams();
    const currentNamespaceId = useSelector(selectCurrentNamespaceId);
    const docIds = useSelector(selectWorkspaceDocumentIds(workspaceId));

    useEffect(() => {
        if(currentNamespaceId && workspaceId) {
            dispatch(fetchWorkspaceDocuments({namespaceId: currentNamespaceId, workspaceId}));
        }
    }, [dispatch, workspaceId, currentNamespaceId]);

    if(!currentNamespaceId) {
        return null;
    }

    const onUploaded = async (fileId: string) => {
        const { documents } = await dispatch(createDocuments({namespaceId: currentNamespaceId, fileIds: [fileId]})).unwrap();
        const promises = documents.map(async (doc: any) => {
            await dispatch(createWorkspaceDocument({workspaceId, documentId: doc.id}));
        });
        await Promise.all(promises);
    }

    return (
        <div className='mx-10'>
            <DocumentFilePicker title="Company Documents" onUploaded={onUploaded}/>
            <div className='max-h-96 overflow-x-hidden overflow-y-scroll'>
                {docIds.map((docId) => (
                    <DocumentFileCapsule key={docId} documentId={docId} />
                ))}
            </div>
        </div>
    );
}

export default WorkspaceDocuments;