import { NavLink } from "react-router-dom"

import{ ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'

const ReportGenWorkflowCard = () => {
    return (
        <div className="mr-4 rounded-xl w-52 h-40 bg-gradient-to-br from-baby-purple-500 to-baby-blue-500 flex flex-col p-4 justify-between">
            <div className="text-white font-semibold text-sm"> Company<br/>Report </div>
            <NavLink to="workspace/memo/new" className="flex justify-center transition hover:scale-110 w-10 h-10 bg-baby-blue-300 rounded-full px-2">
                <ArrowRight fill='white' width={20}/>
            </NavLink>
        </div>
    )
}

export default ReportGenWorkflowCard

