import React, { useState } from 'react';

import './App.css';
import AppRouter from './app/AppRouter';
import './styles/themes.scss';

const App = () => {
  const [theme, setTheme] = useState('default');

  return (
    <div data-theme={theme}>
      <AppRouter />
    </div>
  );
};

export default App;