import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../app/store";
import DocumentUpload from "../DocumentUpload";
import { selectNonTemplateDocumentIds, selectNonTemplateDocuments } from "../../features/document/documentSlice";
import { selectCountNonCompletedDocumentsByIds } from '../../features/document/documentProcessingStatusSlice';
import { ReactComponent as DirectionRight } from '../../assets/direction-right.svg';

const ChatDocument = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const docIds = useSelector((state: RootState) => selectNonTemplateDocumentIds(state));
    const nonCompletedDocumentsCount = useSelector((state: RootState) => selectCountNonCompletedDocumentsByIds(state, docIds));

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`flex-none ${isCollapsed ? 'w-16' : 'w-80'} h-full transition-all duration-300 ease-in-out pt-16 overflow-auto relative`}>
            <div className="flex items-center space-x-2">
                <div className="relative">
                    <button 
                        className="bg-brown-grey-500 hover:bg-brown-grey-700 text-black rounded-full w-10 h-10 flex items-center justify-center" 
                        onClick={toggleCollapse}
                    >
                        <DirectionRight 
                            className={`transition-transform duration-300 ${isCollapsed ? 'rotate-180' : 'rotate-0'}`}
                            width={16}
                            opacity={0.3}
                        />
                    </button>
                    {isCollapsed && nonCompletedDocumentsCount > 0 && (
                        <span className="absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                            {nonCompletedDocumentsCount}
                        </span>
                    )}
                </div>
                {!isCollapsed && (
                    <span className="text-md font-semibold">Company Documents</span>
                )}
            </div>
            {!isCollapsed && <DocumentUpload />}
        </div>
    );
};

export default ChatDocument;
