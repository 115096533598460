import WorkflowCards from "../../components/WorkflowCards";
import WorkspaceHistory from "../../components/WorkspaceHistory";

const HomePreview = () => {
    return (
        <div className="w-full flex flex-col">
            < WorkflowCards />
            < WorkspaceHistory />
        </div>
    );
};

export default HomePreview;