import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { AppDispatch } from '../../app/store';
import { login } from '../../features/user/userSlice';
import { setCurrentUserId, logEvent, setUserProperties } from '../../services/amplitude';
import PrivacyPolicyModal from '../PrivacyPolicyModal'; // Import the modal component

const LoginForm: React.FC = () => {
  const [email, setEmailState] = useState('');
  const [password, setPassword] = useState('');
  const [isPrivacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [aggrementIsPrivacyPolicy, setAggrementIsPrivacyPolicy] = useState(true); // State to control which agreement type to show
  const [isModalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isPrivacyPolicyChecked) {
      alert('Please agree to the Privacy Policy before logging in.');
      return;
    }
    try {
      const response = await dispatch(login({ email, password })).unwrap();
      setCurrentUserId(response.user.id);
      logEvent("login", { email, user_id: response.user.id });

      // Set custom user properties
      setUserProperties({
        email: response.user.email,
        firstName: response.user.first_name,
        lastName: response.user.last_name,
      });
    } catch (error) {
      logEvent("login_failed", { email });
      alert("Encountered an error while logging in, please try again.");
    }
  };

  const handlePrivacyPolicyClick = (isPrivacyPolicy = true) => {
    setAggrementIsPrivacyPolicy(isPrivacyPolicy);
    setModalOpen(true);
  };

  return (
    <div>
      {user && <Navigate to="/home" replace={true} />}
      <div className="flex justify-center pt-28">
        <form className="flex flex-col w-1/3 max-w-3xl" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold dark:text-white">Login</h2>
          <p className="pt-1">Welcome back! Please enter your credentials to log in.</p>
          <div className="pt-10 h-52 flex flex-col justify-between">
            <div className="flex flex-col">
              <label className="font-medium pb-1 text-base" htmlFor="email">Email Address:</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmailState(e.target.value)}
                className="w-full h-11 px-4 border border-gray-300 rounded-md outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Enter Email address"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="font-medium pb-1 text-base" htmlFor="password">
                Password:
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-11 px-4 border border-gray-300 rounded-md outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Enter Password"
                required
              />
            </div>
          </div>
          <div className="flex items-center py-4">
            <input
              id="privacyPolicy"
              type="checkbox"
              checked={isPrivacyPolicyChecked}
              onChange={(e) => setPrivacyPolicyChecked(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="privacyPolicy" className="text-sm text-gray-700">
              I agree to the{' '}
              <span className="underline cursor-pointer text-blue-600" onClick={()=> handlePrivacyPolicyClick(true)}>
                Privacy Policy
              </span>
              {' '}and{' '}
              <span className="underline cursor-pointer text-blue-600" onClick={()=> handlePrivacyPolicyClick(false)}>
                Terms of Service
              </span>
            </label>
          </div>
          <div className="py-5 text-gray-500">
            Need help logging in? <a className="underline" href="#">Contact our team.</a>
          </div>
          <button
            className="self-center h-11 w-48 bg-purple-950 text-white rounded-full font-semibold"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
      <PrivacyPolicyModal isPrivacyPolicy={aggrementIsPrivacyPolicy} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default LoginForm;